import axios from "axios";

let api_url = null;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  api_url = process.env.REACT_APP_API_URL
} else {
  // production code
  api_url = ''
}


// export function getData(ticker) {
// 	const promiseMSFT = fetch(`${api_url}/api/v1/daily/${ticker}`).then(res => res.json()).then(
// 		data => {
// 			let full_data = []
// 			for (var i =0; i < data.data.length; i++) {
// 				var each_date = {
// 					"date": new Date(data.data[i]['date'] * 1000),
// 					"open": data.data[i]['open'],
// 					"high": data.data[i]['high'],
// 					"low": data.data[i]['low'],
// 					"close": data.data[i]['close'],
// 					"volume": data.data[i]['volume'],
// 					"split": data.data[i]['split'],
// 					"dividend": data.data[i]['dividend'],
// 					"absoluteChange": data.data[i]['absoluteChange'],
// 					"percentChange":data.data[i]['percentChange']
// 				}
// 				full_data.push(each_date)

// 			};

// 			console.log(data.raw_date);
// 			return [full_data, data.data, data.info, data.trends_data, data.stopLoss, data.raw_date]
			
// 		})
// 	console.log(promiseMSFT)
// 	// const promiseMSFT = fetch("https://cdn.rawgit.com/rrag/react-stockcharts/master/docs/data/MSFT.tsv")
// 	// 	.then(response => response.text())
// 	// 	.then(data => tsvParse(data, parseData(parseDate)))
// 	return promiseMSFT;
	
// };


export async function getData(ticker, companyName, useAutoComplete) {
	let data = await axios.post(`${api_url}/api/v1/daily`, {
		"companyName": companyName,
		"useAutoComplete": useAutoComplete,
		"id": ticker
	})
	let full_data = []
	
	let stock_data = data.data

	for (var i=0; i < stock_data.data.length; i++) {
		// console.log(data)
		var each_date = {
			"date": new Date(stock_data.data[i]['date'] * 1000),
			"open": stock_data.data[i]['open'],
			"high": stock_data.data[i]['high'],
			"low": stock_data.data[i]['low'],
			"close": stock_data.data[i]['close'],
			"volume": stock_data.data[i]['volume'],
			"split": stock_data.data[i]['split'],
			"dividend": stock_data.data[i]['dividend'],
			"absoluteChange": stock_data.data[i]['absoluteChange'],
			"percentChange":stock_data.data[i]['percentChange']
		};

		full_data.push(each_date);
	};

	console.log(stock_data);
	return {
		full_data,
		data: stock_data.data,
		isChannel: stock_data.isChannel,
		info: stock_data.info,
		trends: stock_data.trends_data,
		stopLoss: stock_data.stopLoss,
		raw_date: stock_data.raw_date,
		_companyName: stock_data.companyName,
		_note: stock_data.notes,
		_notes_last_saved: stock_data.notes_last_saved
	}


	// return [full_data, stock_data.data, stock_data.info, stock_data.trends_data, stock_data.stopLoss, stock_data.raw_date, stock_data.companyName]
};




export function getDateFromIndex(index) {
    return getData().then(data => {
        return data[index]
    })
}
