
import React, {useEffect, useState} from "react";
export function PopUp(props) {
    // show all the check list box available and tick the check box if the item is already in the list
    /*
    
        Upper Channel
        -------------
                Bullish    | Unknown  | Bearish
        Top    |  ☐       |  ☐       |☐
        Unknown|  ☐       |  ☐       |☐
        Bottom |  ☐       |  ☐       |☐


         Lower Channel
        -------------
                Bullish    | Unknown  | Bearish
        Top    |  ☐       |  ☐       |☐
        Unknown|  ☐       |  ☐       |☐
        Bottom |  ☐       |  ☐       |☐

        Range Channel
        -------------
                Bullish    | Unknown  | Bearish
        Top    |  ☐       |  ☐       |☐
        Unknown|  ☐       |  ☐       |☐
        Bottom |  ☐       |  ☐       |☐

    */
    const [data, setData] = useState([]);
    const [_color, _setColor] = useState({
        // macro_range_channelType
        bullish_top_upper: "red",
        bullish_mid_upper: "red",
        bullish_bottom_upper: "red",
        bearish_top_upper: "red",
        bearish_mid_upper: "red",
        bearish_bottom_upper: "red",
        unknown_top_upper: "red",
        unknown_mid_upper: "red",
        unknown_bottom_upper: "red",

        bullish_top_down: "red",
        bullish_mid_down: "red",
        bullish_bottom_down: "red",
        bearish_top_down: "red",
        bearish_mid_down: "red",
        bearish_bottom_down: "red",
        unknown_top_down: "red",
        unknown_mid_down: "red",
        unknown_bottom_down: "red",
        
        bullish_top_range: "red",
        bullish_mid_range: "red",
        bullish_bottom_range: "red",
        bearish_top_range: "red",
        bearish_mid_range: "red",
        bearish_bottom_range: "red",
        unknown_top_range: "red",
        unknown_mid_range: "red",
        unknown_bottom_range: "red",


    })

    useEffect(() => {
        var _data = props.data
        // filter the data by productName
        _data = _data.filter(item => item.productName === props.productName)

        setData(_data);
    }, [props.data])

    function onChangeCheckBox(event, channelType) {
        let checked = event.target.checked;
        let id = event.target.id;
        let macro = id.split("_")[0];
        let range = id.split("_")[1];
        var color_key = macro + "_" + range + "_" + channelType;
        let color = _color[color_key];

        // let color = color;
        if (checked) {
            // Gotta add the item to the list
            let newItem = {
                "Macro": macro,
                "range": range,
                "productName": props.productName,
                "channelType": channelType,
                "color": color
            };

            props.updateData(newItem, "add");
        } else {
            // Gotta remove the item from the list
            let newItem = {
                "Macro": macro,
                "range": range,
                "productName": props.productName,
                "channelType": channelType,
                "color": color
            };

            props.updateData(newItem, "remove");
        }
    };

    function changeColor(e, channelType) {
        console.log(e.target.id);

        let id = e.target.id;
        let macro = id.split("_")[0];
        let range = id.split("_")[1];
        
        // change the color accordingly to the id....
        _setColor({
            [id]: e.target.value,
            // ..._color, // keep the rest of the color
        });


        let changeItem = {
            "Macro": macro,
            "range": range,
            "productName": props.productName,
            "channelType": channelType,
            "color": e.target.value
        };

        props.updateData(changeItem, "edit")
    }

    function returnDataCheckBox(macro, range, channelType) {
        let _data = data.find(item => item.Macro === macro && item.range === range && item.channelType === channelType && item.productName === props.productName)
        if (_data) {
            return (
                <div>
                    <input type="checkbox" id={macro + "_" + range + "_" + channelType} checked={true} onChange={(e) => onChangeCheckBox(e, channelType)} />
                    <select name={macro + "_" + range + "_" + channelType} id={macro + "_" + range + "_" + channelType} onChange={(e) => changeColor(e, channelType)}>
                        <option value="red" selected={_data.color === "red"}>red</option>
                        <option value="green" selected={_data.color === "green"}>green</option>
                        <option value="orange" selected={_data.color === "orange"}>orange</option>
                    </select>

                </div>
            )
        } else {
            return (
                <div>
                    <input id={`${macro}_${range}`} key={`${macro}_${range}`} type="checkbox" checked={false} onChange={(e) => onChangeCheckBox(e, channelType)}/>
                    <select name={macro + "_" + range + "_" + channelType} id={macro + "_" + range + "_" + channelType} onChange={(e) => changeColor(e, channelType)}>
                        <option value="red" selected={_color[macro+"_"+range+ "_" + channelType] === "red"}>red</option>
                        <option value="green" selected={_color[macro+"_"+range+ "_" + channelType] === "green"}>green</option>
                        <option value="orange" selected={_color[macro+"_"+range+ "_" + channelType] === "orange"}>orange</option>
                    </select>
                </div>
            )
           
        }

    };

    return (
        <div className="popup-box">
            <div className="box">
                <span className="close-icon" onClick={props.handleClose}>X</span>
                <p> You are editing {props.productName}</p>
            <p> Upper Channel</p>
            <table>
                <tr>
                    <th></th>
                    <th>Bullish</th>
                    <th>Neutral</th>
                    <th>Bearish</th>
                </tr>
                <tr>
                    <td>Top ({">"}75%)</td>
                        <td>
                            {
                                returnDataCheckBox("bullish", "top", "upper")
                            }
                        </td>
                        <td>
                            {
                                returnDataCheckBox("unknown", "top", "upper")
                            }
                        </td>
                        <td>
                            {
                               returnDataCheckBox("bearish", "top", "upper")
                            }
                        </td>
                </tr>
                <tr>
                    <td>Middle (25% - 75%)</td>
                    <td>
                        {
                          returnDataCheckBox("bullish", "mid", "upper")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("unknown", "mid", "upper")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("bearish", "mid", "upper")
                        }
                    </td>
                </tr>
                <tr>
                    <td>Bottom ({"<"}25%)</td>
                    <td>
                        {
                          returnDataCheckBox("bullish", "bottom", "upper")
                        }
                    </td>
                    <td>
                        {
                           returnDataCheckBox("unknown", "bottom", "upper")
                        }
                    </td>
                    <td>
                        { returnDataCheckBox("bearish", "bottom", "upper")}
                    </td>
            
                </tr>
            </table>

            <p> Range Channel</p>
            <table>
                <tr>
                    <th></th>
                    <th>Bullish</th>
                    <th>Neutral</th>
                    <th>Bearish</th>
                </tr>
                <tr>
                    <td>Top ({">"}75%)</td>
                    <td>
                        {
                            returnDataCheckBox("bullish", "top", "range")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("unknown", "top", "range")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("bearish", "top", "range")
                        }
                    </td>
                </tr>
                <tr>
                    <td>Middle (25% - 75%)</td>
                    <td>
                        {
                            returnDataCheckBox("bullish", "mid", "range")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("unknown", "mid", "range")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("bearish", "mid", "range")
                        }
                    </td>
                </tr>
                <tr>
                    <td>Bottom ({"<"}25%)</td>
                    <td>
                        {
                            returnDataCheckBox("bullish", "bottom", "range")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("unknown", "bottom", "range")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("bearish", "bottom", "range")
                        }
                    </td>
                </tr>
            </table>

            <p> Down Channel</p>
            <table>
                <tr>
                    <th></th>
                    <th>Bullish</th>
                    <th>Neutral</th>
                    <th>Bearish</th>
                </tr>
                <tr>
                    <td>Top ({">"}75%)</td>
                    <td>
                        {
                            returnDataCheckBox("bullish", "top", "down")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("unknown", "top", "down")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("bearish", "top", "down")
                        }
                    </td>
                </tr>
                <tr>
                    <td>Middle (25% - 75%)</td>
                    <td>
                        {
                            returnDataCheckBox("bullish", "mid", "down")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("unknown", "mid", "down")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("bearish", "mid", "down")
                        }
                    </td>
                </tr>
                <tr>
                    <td>Bottom ({"<"}25%)</td>
                    <td>
                        {
                            returnDataCheckBox("bullish", "bottom", "down")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("unknown", "bottom", "down")
                        }
                    </td>
                    <td>
                        {
                            returnDataCheckBox("bearish", "bottom", "down")
                        }
                    </td>
                </tr>
            </table>
            </div>
        </div>
    )
};