import React, {useEffect, useState} from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from "@material-ui/icons/Add";
import { TextField } from "@material-ui/core";


export default function EditPage(props) {
    // show all products
    const [uniqueProducts, setUniqueProducts] = useState([]);
    
    // new product
    const [newProduct, setNewProduct] = useState("");

    useEffect(()=> {
        // only show unique products
        var unique = [...new Set(props.data.map(item => item.productName))];
        unique.sort();
        setUniqueProducts(unique);
    }, [props.data]);

    return (
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
                {
                   uniqueProducts.map((item) => (
                        <TableRow key={item}>
                            <TableCell>
                                {item}
                            </TableCell>
                            <TableCell>
                                <button onClick={(e) => props.togglePopUp(e, item)}><EditIcon/></button>
                            </TableCell>
                        </TableRow>
                        
                    ))
                }
                <TableRow>
                    <TableCell>
                        <TextField onChange={(e) => setNewProduct(e.target.value)} style={{width:"100%"}}/>
                    </TableCell>
                    <TableCell>
                        <button  onClick={(e) => props.togglePopUp(e, newProduct)}><AddIcon /></button>
                    </TableCell>
                </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    )
};