import React, {useState, useEffect} from "react";
// import data from "./new_data.json";
import { PopUp } from './popup';
import "./excelfeature.css";
import EditPage from "./editPage";

export default function ExcelFeature(props) {
    const [channelType, setChannelType] = useState("upper");
    const [macroType, setMacroType] = useState("bullish");
    const [rangeType, setRangeType] = useState("top");
    const [fullData, setFullData] = useState([]);
    const [displayedData, setDisplayedData] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false);
    const [editProduct, setEditProduct] = useState("");
    const [topRangeData, setTopRangeData] = useState([])
    const [middleRangeData, setMiddleRangeData] = useState([])
    const [bottomRangeData, setBottomRangeData] = useState([])
    const [editPage, setEditPage] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // set displayedData according to the two types
        let copy = [...fullData];
        let filtered_data = copy.filter(item => 
           item.Macro === macroType && item.channelType === channelType
    );

        setDisplayedData(filtered_data);
    }, [channelType, macroType, fullData]);

    useEffect(() => {
        // retrieve data from api
        setLoading(true);
        fetch("/api/v1/strategyData", {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        }).then(res => res.json().then(data=> {
            console.log(data['data']);
            setFullData(data['data']);
            setLoading(false);
        }))
    }, []);


    useEffect(() => {
        let copy = [...displayedData];
        let _topRangeData = []
        let _middleRangeData = []
        let _bottomRangeData = []
        for (var i=0; i < copy.length; i++) {
            if (copy[i]['range'] === 'top') {
                _topRangeData.push(copy[i]);
            } else if (copy[i]['range'] === 'mid') {
                _middleRangeData.push(copy[i]);
            } else if (copy[i]['range'] === 'bottom') {
                _bottomRangeData.push(copy[i]);
            };
        };
        // sort _topRangeData by productName
        _topRangeData.sort(function(a, b) {
            var nameA = a.productName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.productName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }
        );

        // sort _middleRangeData by productName
        _middleRangeData.sort(function(a, b) {
            var nameA = a.productName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.productName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }
        );
        
        // sort _bottomRangeData by productName
        _bottomRangeData.sort(function(a, b) {
            var nameA = a.productName.toUpperCase(); // ignore upper and lowercase
            var nameB = b.productName.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }
        );
        // set the states
        setTopRangeData(_topRangeData);
        setMiddleRangeData(_middleRangeData);
        setBottomRangeData(_bottomRangeData);
    }, [displayedData])

    const changeChannelType = (e) => {
        setChannelType(e.target.value);
    };

    const changeMacroType = (e) => {
        setMacroType(e.target.value);
    };

    const changeRangeType = (e) => {
        setRangeType(e.target.value);
    };
    // show edit page
    const toggleEditPage = (e) => {
        e.preventDefault();
        if (editPage === false) {
            var user_input = prompt("Password")
            if (user_input === "Crypto75") {
                setEditPage(!editPage);
            }
        } else {
            setEditPage(!editPage)
        }
    };


    // Show popup edit
    const togglePopUp = (e, productName) => {
        e.preventDefault();
        e.stopPropagation();
        setEditProduct(productName);
        setShowPopUp(!showPopUp);
    };

    const updateData = (data, operation) => {
        console.log(data);
        // Add data to fullDAta
        if (operation === "add") {
            let copy = [...fullData]
            copy.push(data);
            setFullData(copy);
            fetch("/api/v1/strategyData", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(copy)
            }).then((res) => { console.log(res)})
        };

        if (operation === "edit") {
            console.log("Edit")
            let copy = [...fullData];
            // find productName, channelType, macro and range in copy
            let filteredData = copy.filter(item => item.range === data.range && item.Macro === data.Macro && item.channelType === data.channelType && item.productName === data.productName)
            if (filteredData.length !== 0) {
                var _copy = copy.filter(item => item !== filteredData[0]);
                // edit it.. if it does not exist just skip.
                filteredData[0]['color'] = data.color;
                _copy.push(filteredData[0]);
                console.log(_copy);
                setFullData(_copy);
                fetch("/api/v1/strategyData", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(_copy)
                }).then((res) => { console.log(res)})
    
            };
            
        };

        if (operation === "remove") { 
            console.log("remove")
            let copy = [...fullData];
            // find productName, channelType, macro and range in copy
            let filteredData = copy.filter(item => item.range === data.range && item.Macro === data.Macro && item.channelType === data.channelType && item.productName === data.productName)
            if (filteredData.length !== 0) {
                var _copy = copy.filter(item => item !== filteredData[0]);
                // edit it.. if it does not exist just skip.
                setFullData(_copy);
                fetch("/api/v1/strategyData", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(_copy)
                }).then((res) => { console.log(res)})
    
            }
        }

    };

    if (editPage) {
        return (
            <div>
                <button onClick={toggleEditPage}>Back</button>
                <EditPage data={fullData} togglePopUp={togglePopUp}/>
                {
                    showPopUp && <PopUp  handleClose={togglePopUp} productName={editProduct} updateData={updateData} data={fullData} />
                }
            </div>
        )
    }

    return (
        <div>
            <button onClick={props.goToStrategyButton}>Exit</button>
    {
            loading ? (<div>Loading...</div>) : (
                <div>
                <label htmlFor="channelType">Choose a channel:</label>
                <select name="channelType" id="channelType" onChange={changeChannelType} defaultValue={channelType}>
                    <option value="upper" >Upper</option>
                    <option value="range">Range</option>
                    <option value="down">Down</option>
                </select>
    
                <label htmlFor="macroType">Choose Macro:</label>
                <select name="macroType" id="macroType" onChange={changeMacroType} defaultValue={macroType}>
                    <option value="bullish">Bullish</option>
                    <option value="unknown">Neutral</option>
                    <option value="bearish">Bearish</option>
                </select>
    
                <label htmlFor="rangeType">Choose range:</label>
                <select name="rangeType" id="rangeType" onChange={changeRangeType} defaultValue={rangeType}>
                    <option value="top">Top ({">"}75%)</option>
                    <option value="mid">Middle (25% - 75%)</option>
                    <option value="bottom">Bottom ({"<"} 25%)</option>
                </select>
    
                <button onClick={toggleEditPage}>Edit Products</button>
                
                <div className="content">
                    <div className={rangeType==="top" ? "top_range" : "blurDiv"}>
                    <ol key={"top_range"}>
                        {
                            topRangeData.length !== 0 ? topRangeData.map(item => {
                                console.log(item);
                                return (
                               
                                   <li style={{color: item.color}} key={item.Macro + item.rangeType + item.productName + item.color + item.channelType}> {item.productName}</li> 
                                
                            )}) : null
                        }
                    </ol>
    
                    </div>
    
                    <div className={rangeType==="mid"? "mid_range": "blurDiv"}>
                    <ol key={"middleRange"}>
                        {
                            middleRangeData.length !==0 ? middleRangeData.map(item => {
                                console.log(item);
                                return (
    
                                <li style={{color: item.color}} key={item.Macro + item.rangeType + item.productName + item.color + item.channelType}> {item.productName}</li> 
                            )}) : null
                        }
                        </ol>
                    </div>
    
                    <div className={rangeType==="bottom" ? "bottom_range" : "blurDiv"}>
                        <ol key={"bottomRange"}>
                        {
                            bottomRangeData.length !== 0 ? bottomRangeData.map(item => {
                                console.log(item);
                                return (
                                <li style={{color: item.color}} key={item.Macro + item.rangeType + item.productName + item.color + item.channelType}>
                                    {item.productName}
                                </li>
                            )}) : null
                        }
                        </ol>
                        
                    </div>
                </div>
              
            </div>
            )
        }
        </div>
    
      
    )
};