import React, {useState, useEffect} from "react";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'
import axios from 'axios';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import ReplayIcon from '@material-ui/icons/Replay';
// CSS
import './settings.css';

import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const useStyles = makeStyles({
  table: {
    maxWidth: "100%",
  },
  head: {
    backgroundColor: "#fff",
    minWidth: 42,
    // minWidth: "60px"
    // maxWidth: '7%'
  },
  tableContainer: {
    maxHeight: "800px"
  },
});

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 2
  },
  body: {
    backgroundColor: "#ddd",
    minWidth: "50px",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar + 1
  }
}))(TableCell);


const GreenSwtich = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});


export default function Settings({api_url, redirect_to_stock_page}) {
  const classes = useStyles()
  const [formData, setFormData] = useState({
    line_token: null,
    password: null,
  });

  const [showOptions, setShowOptions] = useState(false);
  const [stocks, setStocks] = useState([]);

  const [error, setError] = useState({
    line_token: null,
    password: null
  })

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    })
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    let id = e.currentTarget.id;

    let value = formData[id];

    console.log(id);

    if (value === "" || value === undefined || value === null) {
      setError({
        ...error,
        [id]: `${id} cannot be empty....`
      })
    };


    if (id === "line_token") {
      let api_url_update_line_token = api_url + '/api/v1/updateLineToken'
      axios.post(api_url_update_line_token, {
        line_token: value
      }).then((res) => {
        console.log(res.data)
        setError(res.data.status)
      })
    };


    if (id === "password") {
      let api_url_update_password = api_url + '/api/v1/updatePassword';

      axios.post(api_url_update_password, {
        password: value,
      }).then((res) => {
        console.log(res.data)
        setError(res.data.status)
      })
    };

  };
  
  const [open, setOpen] = useState(false);

  const [openTags, setOpenTags] = useState(false);
  
  const [email, setEmail] = useState('');

  useEffect(() => {
    let api_url_getAllTrendLineByUser = api_url + '/api/v1/get_all_trendline'


    axios.post(api_url_getAllTrendLineByUser).then((res) => {
        console.log(res);
        var _email = res.data.email;
        setEmail(_email);
        // let json_data = JSON.parse(res.data)
        var data = res.data.data;
        // setStocks(res.data.data);

        for (var i = 0; i < data.length; i++) {
          data[i]['saveStatus'] = "Save";
          var dateString = new Date(data[i]['trend_last_updated']);

          data[i]['trend_last_updated'] = dateString;
          console.log(dateString);
          console.log(typeof(dateString))
        };
    
        setStocks(data);
    }).catch((err) => {
      console.log("Settings.js", err);
    });
  }, [])


  let [sortConfig, setSortConfig] = useState({});
  
  useEffect(() => {
    let sortedProducts = [...stocks];

    if (sortConfig !== null) {
      sortedProducts.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setStocks(sortedProducts);
    }
  }, [sortConfig])

  const requestSort = key => {
    let direction = 'ascending';

    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }

    setSortConfig({
      key, direction
    })

  };



  const handleSubmitForStocks = (e) => {
    // Retrieve stock information based on e.target.id
    console.log(e)
    var objectToBeSaved = stocks.find(obj => {
      return obj.ticker === e.currentTarget.id;
    });

    //Submit objectToBeSaved to backend.
    console.log(objectToBeSaved)

    let updater_api_url = api_url + '/api/v1/updateStopLossAndMonitoring'
    axios.post(updater_api_url, {
      objectToBeSaved
    }).then((res) => {
      let tmpStocks = [...stocks];

      let index =  tmpStocks.indexOf(objectToBeSaved);
      

      console.log(tmpStocks[index]);
      tmpStocks[index]['saveStatus'] = "Saved";

      setStocks(tmpStocks);
    })
  };

  
  


  const handleStockMetaDataChange = (e)=> {
    let tmpStocks = [...stocks];
    var objectToBeChanged = tmpStocks.find(obj => {
      return obj.ticker === e.target.name
    });


    let index = tmpStocks.indexOf(objectToBeChanged);

    objectToBeChanged['stopLoss'] = e.target.value;

    tmpStocks[index] = objectToBeChanged;

    setStocks(tmpStocks);
  };

  const handleShowOptions = (e) => {
    setShowOptions(true);
  };

  const handleHideOptions = (e) => {
    setShowOptions(false);
  };

  
  const handleOpen = (e) => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  const handleSwitches = (e) => {
    let tmpStocks =[...stocks];
    var objectToBeChanged = tmpStocks.find(obj => {
        return obj.ticker === e.target.id
    });

    let index =  tmpStocks.indexOf(objectToBeChanged);

    objectToBeChanged['monitoringType'] = e.target.checked;

    tmpStocks[index] = objectToBeChanged;

    setStocks(tmpStocks);

  };




  const handleOpenTags = (e) => {
    setOpenTags(true);
  };

  const handleCloseTags = (e) => {
    setOpenTags(false);
  };

  // This function is to handle the tag changes
  const handleTagsChange = (e) => {
    let tmpStocks = [...stocks];
    var objectToBeChanged = tmpStocks.find(obj => {
      return obj.ticker === e.target.name
    });


    let index = tmpStocks.indexOf(objectToBeChanged);

    
    objectToBeChanged['tags'] = e.target.value;

    tmpStocks[index] = objectToBeChanged;

    setStocks(tmpStocks);
  };



  // This function is to render the column of the table with a sorting mechanism
  const sortButton = (field, columnName) => {
    return (
      <div>
        <button className="noBorder" type="button" onClick={()=> requestSort(field)}>{columnName} </button>
        {
          sortConfig.key === field && sortConfig.direction === 'ascending' ? (
            <ArrowDropUpIcon/>
          ) : sortConfig.key === field && sortConfig.direction === 'descending' ? (
            <ArrowDropDownIcon/>
          ): null
        }
      </div>
      
    )
  };


  const handleEnableBuyNotification = (e) => {
    let api_url_enable_buy_notifications = api_url + "/api/v1/enableBuyNotification";
    console.log(e.currentTarget.id);
    axios.post(api_url_enable_buy_notifications, {
      ticker: e.currentTarget.id,
    }).then((res) => {
      console.log(res);
      // setError(res.data.status);
    })
  };

  return (
    <div>
          {/* <TextField key={"line_token"} id="line_token" label="Line Token" onChange={handleChange}/><Button id="line_token" onClick={handleSubmit}>Update Line Token</Button>
                  <br/>
              <label>{error['line_token']}</label> */}
              <br/>
          <TextField id="password" label="Password" onChange={handleChange}/><Button id="password" onClick={handleSubmit} variant="contained">Update password</Button>
              <br/>
              <label>{error['password']}</label>
              <br/>
            <hr />
          {
            stocks !== null ? <TableContainer component={Paper} className={classes.tableContainer}>
            <Table className={classes.table} aria-label="sticky table" stickyHeader>
              <TableHead>
                <TableRow>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("ticker", "Ticker")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("companyName", "Company Full Name")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("tags", "Long/Short")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("stopLoss", "Stop Loss (%)")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("trend_last_updated", "Last Saved (mm/dd/yyy)")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("lower_bound_price", "Lower Bound")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("lower_bound_percentage", "Lower Bound (%)")}</StickyTableCell>
                  {/* <TableCell align="right">Price change </TableCell> */}
                  <StickyTableCell align="center" className={classes.head}>{sortButton("price_change_per_day_lower_bound", "Lower bound's Gradient (%)")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("last_price", "Last Close")}</StickyTableCell>

                  <StickyTableCell align="center" className={classes.head}>{sortButton("upper_bound_price", "Upper Bound")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("upper_bound_percentage", "Upper bound (%)")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("trend_percentage", "Trend (%)")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("price_change_per_day_upper_bound", "Upper bound's Gradient (%)")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>{sortButton("monitoringType", "Monitoring Type")}</StickyTableCell>
                  <StickyTableCell align="center" className={classes.head}>Save</StickyTableCell>
                  {
                    email === "rrg@perennialpartners.com.sg" ? <StickyTableCell align="center" className={classes.head}>Enable buy notifications</StickyTableCell> : null
                  }
                </TableRow>
              </TableHead>
              <TableBody>
              {
              stocks.map((stock) => {

                return (
                  <TableRow key={stock.ticker}>
                    <TableCell component="th" scope="row">
                      <button className="ticker" type="button" onClick={redirect_to_stock_page} id={stock.ticker}>{stock.ticker}</button>
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {stock.companyName}
                    </TableCell>

                    {/* Tag: --> Long/Short/Watch */}
                    <TableCell component="th" scope="row">
                      <Select
                        name={stock.ticker}
                        open={openTags[stock.ticker]}
                        onClose={handleCloseTags}
                        onOpen={handleOpenTags}
                        value={stock.tags}
                        onChange={handleTagsChange}>
                        <MenuItem value={"Short"}>Short</MenuItem>
                        <MenuItem value={"Long"}>Long</MenuItem>
                        <MenuItem value={"Watch"}>Watch</MenuItem>
                      </Select>

                    </TableCell>
                    
                    {/* Change stop loss to a textfield and monitoring type to a dropdown */}
                    <TableCell align="right">
                        <Select
                        name={stock.ticker}
                        open={open[stock.ticker]}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={stock.stopLoss}
                        onChange={handleStockMetaDataChange}>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={30}>30</MenuItem></Select>
                    </TableCell>
                    <TableCell>{stock['trend_last_updated'].toLocaleDateString()}</TableCell>
                    <TableCell align="right">{stock['lower_bound_price']}</TableCell>
                    <TableCell align="right">{stock['lower_bound_percentage']}</TableCell>
                    <TableCell align="right">{stock['price_change_per_day_lower_bound']}</TableCell>

                    <TableCell align="right">{stock['last_price']}</TableCell>

                    <TableCell align="right">{stock['upper_bound_price']}</TableCell>
                    <TableCell align="right">{stock['upper_bound_percentage']}</TableCell>
                    <TableCell align="right">{stock['trend_percentage']}</TableCell>
                    <TableCell align="right">{stock['price_change_per_day_upper_bound']}</TableCell>

                    <TableCell align="right">  
                    {/* <Select
                      name={stock.ticker}
                      open={open[stock.ticker]}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={stock.monitoringType}
                      onChange={handleStockMetaDataChange}>
                  <MenuItem value={'buy'}>buy</MenuItem>
                  <MenuItem value={'sell'}>sell</MenuItem>
                  <MenuItem value={false}>stop</MenuItem></Select> */}

                    <GreenSwtich className={classes.switchBase} id={stock.ticker} checked={stock['monitoringType']} onChange={handleSwitches} value={stock['monitoringType']} color="secondary"></GreenSwtich>
                  
                  </TableCell>
                  
                  
                  <TableCell align="right">
                    <Button id={stock.ticker} onClick={handleSubmitForStocks} variant="contained" >{stock.saveStatus}</Button>
                  </TableCell>
                    {
                      email === "rrg@perennialpartners.com.sg" ? <TableCell align="right">
                      <Button id={stock.ticker} onClick={handleEnableBuyNotification}><ReplayIcon /></Button>
                  </TableCell>
                  : null
                    }
                    
                  </TableRow>
                )

                
              })}
              </TableBody>
            </Table>
          </TableContainer> : null
          }

          
            
    </div>
      
    );

  
};