import React, { useState, useEffect, Suspense } from 'react';
import { getData } from "./utils";
import axios from 'axios';
// import ReactChart from "./ReactChart";
import { CircularProgress, TextField } from '@material-ui/core';
import SignInSide from './auth/login';
import "./App.css";
import {Button} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/Info';
import Settings from './settings';
import ExcelFeature from './excelFeature';


// import NotificationHistory from "./notificationhistory";

const ReactChart = React.lazy(() => import('./ReactChart'));
const NotificationHistory = React.lazy(() => import('./notificationhistory'));

let api_url = null;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  api_url = process.env.REACT_APP_API_URL
} else {
  // production code
  api_url = ''
}

export default function App() {
  // Data State
  const [data, setData] = useState(null);

  // const [read_Excel_Data, setReadExcelData] = useState(null);

  // const [sd, setSD] = useState([]);
  const sd = [];
  const rrg = null;

  const [id, setId] = useState(null);

  const [companyName, setCompanyName] = useState(null)

  // const [rrg, setRRG] = useState(null);

 
  //Login status
  const [loginState, setLoginState] = useState(false);

  //Error status
  const [errorStatus, setErrorStatus] = useState(null);


  // Trends
  const [trends, setTrends] = useState([])

  // Channels
  const [channels, setChannels] = useState([])

  // StopLoss
  const [stopLoss, setStopLoss] = useState(0)

  // Settings
  const [settings, setSettings] = useState(false);

  // raw date
  const [rawDate, setRawDate] = useState([]);

  // don't use autocomplete
  const [useAutoComplete, setUseAutoComplete] = useState(false);

  // ticker
  const [tick, setTick] = useState("");

  //loading
  const [loading, setLoading] = useState(false);

  // notification history
  const [notificationHistory, setNotificationHistory] = useState([]);

  // notes
  const [notes, setNotes] = useState("");

  // notes last saved
  const [notes_last_saved, setNotesLastSave] = useState("");

  // excelFeature
  const [excelFeature, setExcelFeature] = useState(false);

  // Fetch data when companyName state changes.
  useEffect(() => {
    setData(null);
    setChannels([]);
    setTrends([]);
    // let id_1 = null
    if (companyName !== null) {
      setLoading(true)
      getData(companyName, id, useAutoComplete).then(({full_data, data,isChannel, trends, stopLoss, raw_date, _companyName, _note, _notes_last_saved}) => {
        setData(full_data);
        // setReadExcelData(data);
        if (isChannel) {
          setChannels(trends);
        } else { 
          setTrends(trends);
        }

        setNotes(_note);
        setStopLoss(stopLoss);
        setRawDate(raw_date);
        setId(_companyName);
        setNotes(_note);
        setNotesLastSave(_notes_last_saved);
      }).then(() => {
        setLoading(false);
       
      })
    };


   
  }, [companyName, useAutoComplete]);
  // Show search ticker box when first started.
  
  // Login function
  const loginAPI = async (email, password) => {
    let url = process.env.REACT_APP_API_URL + '/api/v1/login'
    let res = await axios.post(url, {
      'data' :{
          email,
          password
      }
    }, {
      headers: {"Content-Type": "application/json"}
    })

    if (res.data.data === "Sucess") {
      // Set login state
      setLoginState(true);
    };

    if (res.data.data !== "User not found!") {
      setErrorStatus(res.data.data)
    }
  };

  // Logout function
  const logoutAPI = () => {
    axios.get(`${api_url}/api/v1/logout`).then((res) => {
      setLoginState(res.data.data)
      setCompanyName(null);

    })
  };

  useEffect(() => {
    axios.get(`${api_url}/api/v1/isLoggedIn`).then((res) => {
      setLoginState(res.data.data)
      setCompanyName(null);

    })
  }, [])


  if (loginState === false) {
    return <SignInSide loginAPI={loginAPI} errorStatus={errorStatus} />
  };


  const goToSettingsButton = () => {
    setSettings(!settings);
  };



  const setTrendInChart = (trends) => {
    console.log("Chart unmounted")
    setTrends(trends)
  };


  const rawSubmission = () => {
    setCompanyName(tick)
    setTick("");
  };

  const onTickerChange = (e) => {
    let value = e.target.value;
    setTick(value);
  };


  const redirect_to_stock_page = (e) => {
    e.preventDefault();
    setUseAutoComplete(false);
    setCompanyName(e.target.id);
    setSettings(false);
    setExcelFeature(false);
  };

  const goToStrategyButton = (e) => {
    setExcelFeature(!excelFeature);
  };


  const MenuBarComponent = () => {
    return (
                
      <div className="searchBar">
        <button onClick={goToSettingsButton} className="logoutButton"><SettingsIcon /></button>
        <button onClick={goToStrategyButton} className="logoutButton"><InfoIcon /></button>
        {
          <form onSubmit={rawSubmission}>
            <TextField 
              onChange={onTickerChange} value={tick} 
              placeholder={'Exact Ticker from Yahoo! Finance'} variant="outlined" 
              style={{'width': '50%', 'marginLeft': '20%', 'float': 'left', "backgroundColor": "white", 'textTransform': 'uppercase'}} size="small"/></form>
        }
          <Button className="logoutButton" onClick={logoutAPI}>Log out </Button>
      </div>
    )
  };





  if (loginState === true) {
    if (settings === true) {
      return (<div>
        <button onClick={goToSettingsButton}>Exit </button>
          <Settings api_url={api_url} redirect_to_stock_page={redirect_to_stock_page}/>
        </div>)
      
    }

    if (excelFeature === true) {
      return (<div>
          <ExcelFeature goToStrategyButton={goToStrategyButton}/>
        </div>)
    }

    if (companyName == null) {
      return <div>
                {
                  MenuBarComponent()
                }
                <br/>
                <h1 className="pCenter">Version 3.5</h1>
              <img src="/logo_in.png" className="centerImage"></img>
              </div>
    }
  
  
    if (data == null || loading === true) {
      return <div className="center"><CircularProgress /> <p> Loading Chart...</p></div>
    };
  
    // if (data !== null && sd === null) {
    //   return <div className="center"><CircularProgress /> <p> Retrieving channel data....</p></div>
    // };
  
    let width = window.innerWidth;
    let height = window.innerHeight * 0.85;

    let ratio = width/height



  
    if (data !== null && loading === false) {
      return (
        <div>
          {
            MenuBarComponent()
          }
            
          <Suspense fallback={<CircularProgress />}>
          <div className="Chart" id="Chart">
              <ReactChart 
                  data={data} 
                  type="hybrid" 
                  id={id} 
                  sd={sd} 
                  rrg={rrg} 
                  ratio={ratio} 
                  width={width} 
                  height={height}
                  companyName={companyName} 
                  api_url={api_url} 
                  trends={trends}
                  channels={channels}
                  setTrends={setTrends}
                  setChannels={setChannels}
                  logoutAPI={logoutAPI} 
                  setTrendInChart={setTrendInChart}
                  stopLoss={stopLoss}
                  rawDate={rawDate}
                  notificationHistory={notificationHistory}
                  notes={notes}
                  setNotes={setNotes}
                  notes_last_saved={notes_last_saved}/></div>
            <NotificationHistory api_url={api_url} id={companyName} setNotificationHistory={setNotificationHistory}/>
          </Suspense>
           
        </div>
      )
    }
  }







};
